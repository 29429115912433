// @ts-nocheck
import { observable, action, makeObservable } from 'mobx';

class AdminTokenStore {
  subscribers = [];
  token;

  setToken = (newToken) => {
    this.token = newToken;

    this.subscribers.forEach((sub) => sub(newToken));
  };

  subscribe = (listener) => {
    this.subscribers.push(listener);

    return () => (this.subscribers = this.subscribers.filter((sub) => sub !== listener));
  };

  constructor() {
    makeObservable(this, {
      token: observable,
      setToken: action,
    });
  }
}

const ADMIN_TOKEN_STORE = new AdminTokenStore();

export default ADMIN_TOKEN_STORE;
