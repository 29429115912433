import { STAGE } from '@admin/shared-local-storage-consts';

const lsKey = (k: string) => `permission_simulator.${k}`;
const env: { [key: string]: boolean } = { development: true, local: true };

const PermissionSimulator = {
  isEnabled: env[STAGE] && localStorage.getItem(lsKey('enabled')) !== null,
  roles: localStorage.getItem(lsKey('roles')),
  setIsEnabled: (isEnabled: boolean) =>
    isEnabled ? localStorage.setItem(lsKey('enabled'), 'true') : localStorage.removeItem(lsKey('enabled')),
  setRoles: (roles: string[]) => localStorage.setItem(lsKey('roles'), roles.join(',')),
};

export default PermissionSimulator;
