export const giger = /^admin(-dev-\w+)?\.(deel|giger)\.training$/.test(window.location.hostname);
const { REACT_APP_API_URL = '', REACT_APP_DEEL_FRONT = '', REACT_APP_PRM_DOMAIN = '' } = import.meta.env;
export const DEFAULT_API_URL = giger
  ? window.location.origin.replace('://admin-', '://api-').replace('://admin.', '://api.')
  : REACT_APP_API_URL;
export const DEFAULT_PRODUCT_APP_URL = giger
  ? window.location.origin.replace('://admin-', '://www-').replace('://admin.', '://www.')
  : REACT_APP_DEEL_FRONT;
export const DEFAULT_PRODUCT_PRM_DOMAIN = giger
  ? window.location.origin.replace('://admin-', '://www-').replace('://admin.', '://www.')
  : REACT_APP_PRM_DOMAIN;
export const PRODUCT_PRM_DOMAIN = localStorage.getItem('REACT_APP_PRM_DOMAIN') || DEFAULT_PRODUCT_PRM_DOMAIN;
export const OAUTH2_ADMIN_URL = DEFAULT_API_URL.replace('://api', '://auth');
