import log from '@admin/shared-logging/log';
import type { Canceler, CancelToken as AxiosCancelToken } from 'axios';
import axios from 'axios';

const CancelToken = axios.CancelToken;

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type NonNullableFields<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export function makeCancellableApiCall<A extends any[], R>(func: (...args: [...A, AxiosCancelToken]) => Promise<R>) {
  let canceler: Canceler | null = null;

  return async (...args: A) => {
    try {
      canceler?.();
      const source = CancelToken.source();
      canceler = source.cancel;

      return await func(...args, source.token);
    } catch (error) {
      if (!axios.isCancel(error)) log.error(error);
      throw error.response?.data || error.message || error;
    }
  };
}

export function makeApiCall<A extends any[], R>(func: (...args: A) => Promise<R>) {
  return async (...args: A) => {
    try {
      return await func(...args);
    } catch (error) {
      if (!axios.isCancel(error)) log.error(error);
      throw error.response?.data || error.message || error;
    }
  };
}
