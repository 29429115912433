import type { ReactChild } from 'react';
import { PermissionProvider, Permission } from '@letsdeel/ui/dist/shared';
import type { PermissionsMap } from '@letsdeel/ui/dist/shared/PermissionProvider/PermissionProvider.props';
import { useCurrentUser } from '@admin/shared-contexts/CurrentUser';
import type { RoleName } from '@/types/authorization/authorization';

interface Props {
  children: ReactChild;
}

export const PERMISSIONS_DEBUG_KEY = 'permissionDebug';

const AuthorizationProvider = ({ children }: Props) => {
  const user = useCurrentUser();
  const debugModes = localStorage.getItem(PERMISSIONS_DEBUG_KEY)
    ? JSON.parse(localStorage.getItem(PERMISSIONS_DEBUG_KEY)!)
    : false;
  const showOrgSets = debugModes?.includes?.('orgSets') || debugModes?.includes?.('allSets');
  const roles = user ? [...(user.roles || []), ...(user.permissionSets || [])] : [];
  return (
    <PermissionProvider permissionMapping={user.newPermissions as PermissionsMap} debug={Boolean(debugModes)}>
      <Permission.RoleProvider
        addRoles={[...(user?.roles || []), ...(user?.permissionSets || [])] as RoleName[]}
        {...(debugModes ? { ['data-debug']: showOrgSets ? `ORG-LEVEL: ${roles.join(', ')}` : null } : [])}
      >
        {children}
      </Permission.RoleProvider>
    </PermissionProvider>
  );
};

export default AuthorizationProvider;
