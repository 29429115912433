import React, { useContext } from 'react';
import type { Me } from '@admin/shared-api';

//todo: make one place for all user logic
export interface CurrentUser extends Me {
  /**
   * @deprecated use Permission.Restricted or useRestricted hook from UI
   */
  hasPermission: (permission: string) => boolean;
}

export function makeCurrentUser(data: Me): CurrentUser {
  return {
    ...data,
    hasPermission: (permission: string) => !!data && data.permissions.includes(permission),
  };
}

export function useCurrentUser() {
  return useContext(CurrentUserContext);
}

//no null in type as we make sure useContext always used when user already loaded
const CurrentUserContext = React.createContext<CurrentUser>(null as any);
CurrentUserContext.displayName = 'CurrentUserContext';

export default CurrentUserContext;
