import type { AxiosInstance } from 'axios';
import axios from 'axios';
import ADMIN_TOKEN_STORE from './adminTokenStore';
import { API_URL, PRODUCT_APP_URL } from '@admin/shared-local-storage/api';
import PermissionSimulator from '../permission_simulator';

export function makeClient(baseURL?: string, options = {}): AxiosInstance {
  const client = axios.create({
    baseURL,
    ...options,
  });

  return client;
}

export function makeAdminClient(baseURL?: string): AxiosInstance {
  const isOverridingMaintenance = !!localStorage.getItem('maintenance_override');
  const shouldSetPaymentsProxyHeader = sessionStorage.getItem('should_set_payments_proxy_header') === 'true';

  const client = makeClient(baseURL ? `${API_URL}/${baseURL}` : API_URL, {
    headers: Object.assign(
      { 'x-app-host': PRODUCT_APP_URL.replace(/^https?:\/\//, '') },
      isOverridingMaintenance ? { 'x-maintenance': 'ignore' } : {},
      shouldSetPaymentsProxyHeader ? { 'x-proxy-to': 'payments' } : {}
    ),
  });
  if (PermissionSimulator.isEnabled) {
    client.defaults.headers['x-simulate-permissions'] = true;
    client.defaults.headers['x-simulated-permissions'] = PermissionSimulator.roles;
  }

  client.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err?.response?.status === 401) {
        // Token expired, or hacked
        ADMIN_TOKEN_STORE.setToken(null);
      }
      return Promise.reject(err);
    }
  );

  const updateToken = (token: string | null) => {
    if (token) {
      client.defaults.headers['x-auth-token'] = token;
    } else {
      if (client.defaults.headers) delete client.defaults.headers['x-auth-token'];
    }
  };

  updateToken(ADMIN_TOKEN_STORE.token);
  ADMIN_TOKEN_STORE.subscribe(updateToken);

  return client;
}
