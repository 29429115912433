// reason: https://github.com/vitejs/vite/issues/12423#issuecomment-2080351394
//eslint-disable-next-line no-restricted-imports
import '@mui/material/styles/styled';
import { STRICT_URL_REGEX } from '@/utils/regex';
import * as Sentry from '@sentry/react';
import { configure } from 'mobx';
import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import Preloader from './components/Preloader';
import { giger } from '@admin/shared-consts/api';
import log from '@admin/shared-logging/log';
import { PERMISSIONS_DEBUG_KEY } from './providers/Authorization/AuthorizationProvider';
import ADMIN_TOKEN_STORE from '@admin/shared-api/support/adminTokenStore';
import { getCookieToken, setCookieToken } from '@admin/shared-api/support/auth';

configure({ enforceActions: 'never' });

declare global {
  var log: typeof import('@admin/shared-logging/log').default;
}

window.log = log; // Makes LogLevel globally accessible

const AppComponent = lazy(() => import('./App'));
const App = () => (
  <Suspense fallback={<Preloader />}>
    <AppComponent />
  </Suspense>
);

const setApiURL = (url: string) => {
  localStorage.setItem('REACT_APP_API_URL', url);
  location.reload();
};

// @ts-ignore
window._localApi = (port = '3030') => {
  setApiURL(`http://localhost:${port}`);
};

// @ts-ignore
window._devApi = () => {
  setApiURL(`https://api-admin.deel.training`);
};

// @ts-ignore
window._gigerApi = (gigerEnv) => {
  const isURL = gigerEnv.match(STRICT_URL_REGEX);
  setApiURL(isURL ? gigerEnv : `https://api-${gigerEnv}.giger.training`);
};

// @ts-ignore
window._localFrontend = (port) => {
  localStorage.setItem('REACT_APP_DEEL_FRONT', `http://localhost:${port || 3001}`);
  localStorage.setItem('REACT_APP_PRM_DOMAIN', `http://localhost:${port || 3002}`);
  location.reload();
};

// @ts-ignore
window._devFrontend = () => {
  localStorage.removeItem('REACT_APP_DEEL_FRONT');
  localStorage.removeItem('REACT_APP_PRM_DOMAIN');
  location.reload();
};
// @ts-ignore
window._gigerFrontend = (gigerEnv) => {
  const isURL = gigerEnv.match(STRICT_URL_REGEX);
  localStorage.setItem('REACT_APP_DEEL_FRONT', isURL ? gigerEnv : `https://www-dev-${gigerEnv}.giger.training`);
  localStorage.setItem('REACT_APP_PRM_DOMAIN', isURL ? gigerEnv : `https://www-dev-${gigerEnv}.giger.training`);
  location.reload();
};

const getAuthUser = async () => {
  //Temporary check for token in local storage
  const oldToken = localStorage.getItem('token');

  if (oldToken) {
    await setCookieToken(oldToken);
    ADMIN_TOKEN_STORE.setToken(oldToken);
    localStorage.removeItem('token');
  }

  if (!ADMIN_TOKEN_STORE.token) {
    const token = await getCookieToken();
    ADMIN_TOKEN_STORE.setToken(token);
  }
};

Sentry.init({
  enabled: window.location.hostname !== 'localhost' && !giger,
  release: import.meta.env.REACT_APP_CI_COMMIT_SHA,
  dsn: import.meta.env.REACT_APP_SENTRY_DSN,
  environment: import.meta.env.REACT_APP_SENTRY_ENVIRONMENT,
});

const container = document.getElementById('root');

(async () => {
  if (container) {
    await getAuthUser();
    const root = createRoot(container);
    root.render(<App />);
  }
})();

const PERMISSIONS_DEBUG_OPTIONS = ['orgSets', 'teamSets', 'allSets', 'noSets'];

// @ts-ignore
window._permissionsDebug = (...args) => {
  if (args.length === 0 && localStorage.getItem(PERMISSIONS_DEBUG_KEY)) localStorage.removeItem(PERMISSIONS_DEBUG_KEY);
  else localStorage.setItem(PERMISSIONS_DEBUG_KEY, JSON.stringify(args.length === 0 ? ['noSets'] : args));
  // eslint-disable-next-line no-console
  console.log('Permissions debug mode is now', localStorage.getItem(PERMISSIONS_DEBUG_KEY) ? 'ON' : 'OFF');
  // eslint-disable-next-line no-console
  console.log(`Possible options: `, PERMISSIONS_DEBUG_OPTIONS);
  location.reload();
};
