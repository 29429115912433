import { makeApiCall } from '@admin/shared-api/helpers';
import { makeAdminClient } from './makeClient';

const client = makeAdminClient('');

export const getCookieToken = makeApiCall(async () => {
  const {
    data: { token },
  } = await client.post('/login/get-token', {}, { headers: { 'x-auth-token-type': 'admin' }, withCredentials: true });
  return token;
});

export const setCookieToken = makeApiCall(async (token, oldToken?) => {
  await client.post(
    '/login/set-token',
    { token },
    { headers: { 'x-auth-token-type': 'admin', ...(oldToken && { 'x-auth-token': oldToken }) }, withCredentials: true }
  );
  return token;
});
