import type { LogLevelDesc } from 'loglevel';
import log from 'loglevel';

log.setLevel((import.meta.env.LOG_LEVEL || import.meta.env.REACT_APP_LOG_LEVEL || log.levels.ERROR) as LogLevelDesc);
log.info('log.level:', Object.keys(log.levels)[log.getLevel()]);

// Internal cache for time() and timeEnd() calls
const _timers: { [name: string]: number } = {};

declare module 'loglevel' {
  export interface RootLogger {
    time: (id: string) => void;
    timeEnd: (id: string) => void;
  }
}

/**
 * Analog of console.time()
 */
log.time = (id) => {
  const time = window.performance.now();
  const timerName = `${id}_start`;

  if (_timers[timerName]) {
    log.warn(`Timer "${id}" already exists. Call log.timeEnd("${id}") to reset the timer.`);
    return false;
  }

  _timers[timerName] = time; // Save Timer in the Cache
  // log.info(`id - startedAt:`, time);
  return time;
};

/**
 * Analog of console.timeEnd()
 */
log.timeEnd = (id) => {
  const timerName = `${id}_start`;
  const timerStart = _timers[timerName];
  if (!timerStart) {
    log.warn(`Timer "${id}" does not exist. Call log.time("${id}") first to create the timer.`);
    return -1;
  }
  const timeEnd = window.performance.now();
  const totalTime = timeEnd - timerStart;
  // Todo: Add average time counters if some option is set
  // const amount = (_timers[id + '_amount'] = _timers[id + '_amount'] ? _timers[id + '_amount'] + 1 : 1);
  // const sum = (_timers[id + '_sum'] = _timers[id + '_sum'] ? _timers[id + '_sum'] + time : time);
  // _timers[id + '_avg'] = sum / amount;

  delete _timers[timerName];
  log.info(`${id} - executionTime:`, totalTime);
  return totalTime;
};

// Analog of console.log() in case it is missing
if (!log.log || typeof log.log !== 'function') {
  log.log = log.info; // Just alias to info()
}

export default log;
